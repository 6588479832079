import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./utils/PrivateRoute";
import { useKeycloak } from "@react-keycloak/web";

// import Login from "./pages/Login";

import Dashboard from "./pages/Dashboard";
import "./css/common.scss";
function AppRouter() {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }
  //sessionStorage.getItem("keyclok");

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AppRouter;
