import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "./services/keycloak";
import AppRouter from "./AppRouter";
import "./css/common.scss";

function App() {
  const [userSub, setUserSub] = useState(null);
  const tokenLogger = (tokenData) => {
    if (tokenData.token) {
      window.localStorage.setItem("userToken", tokenData.token);
      getUserInfo();
    }
  };

  const getUserInfo = async () => {
    try {
      const userData = await keycloak.loadUserInfo();
      window.localStorage.removeItem("userInfo");
      window.localStorage.setItem("userInfo", JSON.stringify(userData) || {});
      setUserSub(userData.sub);
    } catch (error) {
      console.log(error);
    }
  };

  const eventLogger = (event) => {};
  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: "login-required" }}
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <AppRouter />
    </ReactKeycloakProvider>
  );
}

export default App;
