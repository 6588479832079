import React, { useState } from "react";
import { Col, Row, Dropdown } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import nccaLogo from "../images/gileadLogo.svg";
import moduleIcon from "../images/moduleAppIcon.svg";
import moduleIconHover from "../images/moduleAppIconHover.svg";
import emailIcon from "../images/emailAppIcon.svg";
import emailIconHover from "../images/emailAppIconHover.svg";
import edetailIcon from "../images/edetailIcon.svg";
import bannerIcon from "../images/bannerIcon.svg";
import sparc1x from "../images/sparc.png";
import sparc2x from "../images/sparc@2x.png";
import sparc3x from "../images/sparc@3x.png";
import notificationIcon from "../images/notificationIcon.svg";
import LogoutDropdownCard from "../components/LogoutDropdownCard";

const Dashboard = (props) => {
  const [value, setValue] = useState("");
  const { keycloak } = useKeycloak();
  const getFirstLetters = () => {
    const userName = !!keycloak.authenticated
      ? keycloak.tokenParsed.name
      : null;
    const nameArray = userName.split(" ");
    const firstLetters = nameArray.map((name) => name.charAt(0));
    return firstLetters.join("");
  };
  const getUserNameEmail = () => {
    const userName = !!keycloak.authenticated ? keycloak : null;
    return userName;
  };
  const logoutButton = React.forwardRef(({ children, onClick }, ref) => (
    <div
      title="Logout"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="d-flex userName align-items-center">
        {" "}
        {getFirstLetters()}
      </div>
    </div>
  ));

  // Dropdown needs access to the DOM of the Menu to measure it
  const logoutMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={`pt15 pb15 ${className}`}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled mb-0 appDropdown">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  return (
    <div className="container-fluid">
      <Row className="align-items-center ptpb15">
        <div className="col-6">
          <img src={nccaLogo} alt="NCCA" />
        </div>
        <div className="col-6 text-end">
          <div class="d-flex justify-content-end">
            <span className="d-none">
              <img
                src={notificationIcon}
                alt="Notifications"
                className="me-2"
              />
            </span>
            <div>
              <Dropdown className="logOutButton">
                <Dropdown.Toggle as={logoutButton} id="logoutButton" />
                <Dropdown.Menu as={logoutMenu}>
                  <LogoutDropdownCard
                    getFirstLetters={getFirstLetters}
                    getUserNameEmail={getUserNameEmail()}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Row>
      <Row className="bannerBorder">
        <Col className="bannerImage"></Col>
      </Row>
      <Row className="align-items-center justify-content-between">
        <div className="col-6 welcomeTitle ps-5 pt-3 pb-3">
          <h1>
            Hi{" "}
            {!!keycloak.authenticated ? keycloak.tokenParsed.given_name : null},
            Welcome
            <span>What would you like to create today?</span>
          </h1>
        </div>
        <div className="col-6 text-end pe-5">
          <div class="d-flex justify-content-end">
            <div className="damsList">
              <span>Source</span>
              <span>PromoMats</span>
            </div>
          </div>
        </div>
      </Row>
      <Row className="align-items-center nextApplicationsRow">
        <Col className="ps-0">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <div className="text-center pr-30 iconBox">
                <a
                  href="https://contentstudio.module.gilead.com"
                  className="nccaModuleIcon"
                >
                  <img
                    src={moduleIcon}
                    alt="NCCA Module"
                    onMouseOver={(e) => (e.currentTarget.src = moduleIconHover)}
                    onMouseOut={(e) => (e.currentTarget.src = moduleIcon)}
                  />
                </a>
                <span>Modules</span>
              </div>
              <div className="text-center iconBox">
                <a
                  href="https://contentstudio.email.gilead.com"
                  className="nccaEmailIcon"
                >
                  <img
                    src={emailIcon}
                    alt="NCCA Email"
                    onMouseOver={(e) => (e.currentTarget.src = emailIconHover)}
                    onMouseOut={(e) => (e.currentTarget.src = emailIcon)}
                  />
                </a>
                <span>Email</span>
              </div>
            </div>
            <div style={{ paddingRight: "32px" }}>
              <a
                href="https://sparc.service-now.com/services?id=sc_cat_item&sys_id=c68731bc378d0300580265e2b3990e73"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={sparc1x}
                  srcset={`${sparc1x} 1x, ${sparc2x} 2x, ${sparc3x} 3x`}
                  alt=""
                />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
