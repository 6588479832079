import React from "react";
import styles from "../css/logoutCard.module.scss";
import { useKeycloak } from "@react-keycloak/web";

const LogoutDropdownCard = (props) => {
  const { getFirstLetters, getUserNameEmail } = props;
  const { keycloak } = useKeycloak();
  const userLogout = () => {
    keycloak.logout({ redirectUri: "https://contentstudio.gilead.com" });
  };
  return (
    <div className={styles.logoutCard}>
      <div className="d-flex justify-content-center">
        <div className={styles.userNameOuterLine}>
          <span className={styles.userName}> {getFirstLetters()}</span>
        </div>
      </div>
      <div className={styles.logoutCardName}>
        {getUserNameEmail?.userInfo.name}
      </div>
      <div className={styles.logoutCardEmail}>
        {getUserNameEmail?.userInfo.email}
      </div>
      <div className="text-center">
        <button
          className={`btn btn-link ${styles.logoutBtn}`}
          type="button"
          onClick={userLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};
export default LogoutDropdownCard;
